export default [
  {
    prop: 'user_id',
    label: 'User ID',
    type: 'text',
    link: 'irace.vn/races/users',
    external: true,
    minWidth: 100
  },
  {
    prop: 'user_name',
    label: 'User',
    type: 'text',
    minWidth: 180
  },
  {
    prop: 'date',
    label: 'Ngày',
    type: 'datetime',
    minWidth: 180
  },
  {
    prop: 'step',
    label: 'Steps',
    type: 'text',
    minWidth: 80
  },
  {
    prop: 'source',
    label: 'Source',
    type: 'text',
    minWidth: 150
  },
  {
    prop: 'is_deleted',
    label: 'Hiển thị',
    type: 'badge',
    minWidth: 120
  },
  {
    prop: 'link',
    label: 'Link',
    type: 'link',
    minWidth: 300,
    external: true
  }
]
